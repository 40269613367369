<template>
    <v-row v-if="item" align="center" v-ripple @click="$emit('click', item)">
        <v-col cols="2" align="right">
            <v-btn fab x-small depressed :loading="item.loading || false" :color="color">
                <v-icon small v-text="icon"/>
            </v-btn>
        </v-col>
        <v-col cols="10">
            <div>
                <span v-if="item.type">{{ item.type }}</span>
                {{ item.name }}
                <span v-if="withPhone" class="phone-text">+56{{ item.phone }}</span>
            </div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: "ItemCard",
    props: {
        item: {
            type: Object,
            default: null,
        },
        icon: {
            type: String,
            default: null,
        },
        color: {
            type: String,
            default: 'primary'
        },
        withPhone: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped>

.phone-text {
    font-size: 11px;
    font-weight: bold;
    color: var(--v-primary-base);
}

</style>