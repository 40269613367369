<template>
    <v-container>
        <questionnaire-success-card v-if="finish"/>
        <orders-and-laboratories v-else @finish="onFinish"/>
    </v-container>
</template>

<script>


import OrdersAndLaboratories from "@/components/client/OrdersAndLaboratories";
import QuestionnaireSuccessCard from "@/components/client/QuestionnaireSuccessCard";
export default {
    name: "ClientQuestionnaireFinished",
    components: {QuestionnaireSuccessCard, OrdersAndLaboratories},
    data() {
        return {
            finish: false,
        }
    },
    methods: {
        onFinish() {
            const vm = this
            vm.finish = true
        }
    }
}
</script>

<style scoped>


</style>