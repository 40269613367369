<template>
    <div class="center-all text-center">
        <div>
            <v-img class="mx-auto" width="180" :src="imgLogoDark"/>
        </div>
        <div class="subtitle-success">
            Cuando tenga los resultados de sus exámenes, súbalos
            a esta plataforma y el médico será notificado de
            manera inmediata.
        </div>
        <v-btn color="primary" x-large depressed class="normal-btn mt-5" @click="onDashboard">
            Dashboard
        </v-btn>
        <br>
        <logout-button class="mt-3"/>
    </div>
</template>

<script>
import LogoutButton from "@/components/common/LogoutButton";
export default {
    name: "QuestionnaireSuccessCard",
    components: {LogoutButton},
    methods: {
        onDashboard() {
            const vm = this
            vm.goTo({name: 'client.home'})
        }
    }
}
</script>

<style scoped>

.center-all {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.subtitle-success {
    font-size: 14px;
    line-height: 16px;
    color: #1a1a1a;
    margin-top: 32px;
    margin-bottom: 12px;
}

</style>