<template>
    <master-panel pa0>
        <template v-slot:body>
            <v-row>
                <v-col cols="12" class="mt-3 section">
                    {{
                        clientView ?
                            'Puede descargar sus órdenes de exámenes aquí:' :
                            'Gracias por tus respuestas. En tu correo electrónico ya están disponibles tus órdenes de exámenes. Usted ha finalizado exitosamente el formulario y desde ya podrá descargar sus órdenes de exámenes:'
                    }}
                </v-col>
                <v-col cols="12">
                    <item-card
                        v-for="(order, i) in orders"
                        :key="i" :item="order"
                        color="secondary"
                        icon="$download"
                        @click="onOrderClick"
                    />
                </v-col>
                <v-col cols="12" class="mt-3 section" v-if="laboratories.length > 0">
                    Para mayor celeridad de sus exámenes, le
                    recomendamos los siguientes centros médicos
                </v-col>
                <v-col cols="12" v-if="loading">
                    <loading-spinner line height=""/>
                </v-col>
                <v-col cols="12" v-else>
                    <item-card
                        v-for="(laboratory, i) in laboratories"
                        :key="i" :item="laboratory"
                        icon="$phone"
                        :with-phone="!!laboratory.phone"
                        @click="onLaboratoryClick"
                    />
                </v-col>
                <v-col cols="12" class="mt-3 section">
                    *Recuerde cargar los informes de sus exámenes
                    en esta misma plataforma para la evaluación
                    del equipo médico.
                </v-col>
            </v-row>
        </template>
        <template v-slot:buttons>
            <v-btn v-if="clientView" color="primary" class="normal-btn" small depressed @click="goBack">
                Volver
            </v-btn>
            <v-btn v-else color="secondary" x-large depressed class="normal-btn" @click="$emit('finish')">
                Finalizar
            </v-btn>
        </template>
    </master-panel>
</template>

<script>
import ItemCard from "@/components/cards/ItemCard";
import MasterPanel from "@/components/layouts/MasterPanel";
import httpService from "@/services/httpService";
import LoadingSpinner from "@/components/common/LoadingSpinner";
import {DOCUMENTS} from "@/utils/Constants";

export default {
    name: "OrdersAndLaboratories",
    components: {LoadingSpinner, MasterPanel, ItemCard},
    props: {
        clientView: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            loading: false,
            orders: [
                {name: 'Exámenes de laboratorio', document_type_id: DOCUMENTS.LABORATORY, loading: false,},
                {name: 'Examen de Endoscopia', document_type_id: DOCUMENTS.ENDOSCOPY, loading: false,},
                {name: 'Examenes Cardiológicos', document_type_id: DOCUMENTS.CARDIOLOGICAL, loading: false,},
                {name: 'Examen de Ecografía', document_type_id: DOCUMENTS.SONOGRAPHY, loading: false,},
            ],
            laboratories: [],
        }
    },
    computed: {
        doctorId(vm = this) {
            return vm.currentUser?.doctor_id || null
        }
    },
    mounted() {
        const vm = this
        vm.loadLaboratories()
    },
    methods: {
        async loadLaboratories() {
            const vm = this
            vm.loading = true

            let response = await httpService.get(`doctor/${vm.doctorId}/laboratories`)
            // console.log('loadLaboratories', response)
            vm.laboratories = response.data.laboratories

            vm.loading = false
        },
        async onOrderClick(order) {
            const vm = this
            order.loading = true
            let response = await httpService.getFile(`documents/${order.document_type_id}/user/${vm.currentUser.id}/pdf`)
            const url = URL.createObjectURL(new Blob([response.data], {type: 'application/pdf'}));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${order.name}.pdf`);
            document.body.appendChild(link);
            link.click();
            order.loading = false
        },
        onLaboratoryClick(laboratory) {
            if (laboratory.phone) window.open(`tel:+56${laboratory.phone}`);
        },
    }
}
</script>

<style scoped>

.section {
    font-size: 13px;
    line-height: 15px;
    color: #1a1a1a;
    text-align: center;
    font-weight: bold;
}

</style>